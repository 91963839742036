var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{staticClass:"mt-3 mt-md-0",attrs:{"title":'Author Details',"show-on-small-screen-only":""}},[_c('table',{staticClass:"pubs-table mx-auto mx-md-0"},[_c('tr',[_c('td',[_vm._v("MR Author ID")]),_c('td',[_vm._v(_vm._s(_vm.author.id))])]),_c('tr',[_c('td',[_vm._v("Earliest Indexed Publication")]),_c('td',[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(_vm.author.earliestPublicationYear)+" ")]):_c('RouterLink',{attrs:{"to":{
              name: 'PublicationsSearch',
              query: {
                query: `auid:${_vm.author.id} y:${_vm.author.earliestPublicationYear}`,
              }
            }}},[_vm._v(" "+_vm._s(_vm.author.earliestPublicationYear)+" ")])],1)]),_c('tr',[_c('td',[_vm._v("Total Publications")]),_c('td',[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(_vm.author.totalPublications)+" ")]):_c('RouterLink',{attrs:{"to":{
              name: 'PublicationsSearch',
              query: {
                query: `auid:${_vm.author.id}`,
              },
            }}},[_vm._v(" "+_vm._s(_vm.author.totalPublications)+" ")])],1)]),_c('tr',[_c('td',[_vm._v("Total Related Publications")]),_c('td',[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(_vm.author.totalRelatedPublications)+" ")]):_c('RouterLink',{attrs:{"to":{
              name: 'PublicationsSearch',
              query: {
                query: `mauid:${_vm.author.id}`,
              },
            }}},[_vm._v(" "+_vm._s(_vm.author.totalRelatedPublications)+" ")])],1)]),_c('tr',[_c('td',[_vm._v("Total Citations")]),_c('td',[_vm._v(" "+_vm._s(_vm.author.totalCitations)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }