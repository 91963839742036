
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Author } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class CoAuthors extends Vue {
  @Prop({ required: true }) author!: Author

  //
  // REACTIVE PROPERTIES
  //
  perPage = 5
  fields = [
    {
      key: 'description',
      label: 'Classification',
      sortable: true,
      thClass: 'user-select-none',
      formatter: (value: string) => {
        if (value.toLowerCase() === 'miscellaneous') {
          return 'Other'
        }

        return value.replace(/\[.*?\]|\{.*?\}/g, '')
      },
      tdAttr: (value: string) => {
        return {
          title: value,
        }
      },
    },
    {
      key: 'total',
      label: 'Citations',
      sortable: true,
      thClass: 'user-select-none',
      class: 'text-center',
    },
  ]

  showMoreItems() {
    this.perPage += 5
  }
}
