
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Card } from '@/components/card'
import { AuthorSerialByAuthorResult } from './types'

@Component({
  components: {
    Card,
  },
})
export default class PublicationsBySerial extends Vue {
  @Prop({ required: true }) pubType!: string
  @Prop({ required: false }) serialResults!: AuthorSerialByAuthorResult[]
  @Prop({ required: true }) authorId!: number

  page = 1
  resultsPerPage = 5

  filter = 'most'
  filterModes = [
    {
      value: 'most',
      text: 'Most Published',
    },
    {
      value: 'least',
      text: 'Least Published',
    },
    {
      value: 'alpha',
      text: 'Alphabetical',
    },
  ]

  get sortedResults() {
    // Sort by whatever the user selects
    switch (this.filter) {
      case 'alpha':
        return this.serialResults.sort((a, b) => { return a.title.localeCompare(b.title) })
      case 'least':
        return this.serialResults.sort((a, b) => { return a.totalPublications - b.totalPublications })
      case 'most':
      default:
        return this.serialResults.sort((a, b) => { return b.totalPublications - a.totalPublications })
    }
  }

  get selectedResults() {
    return this.sortedResults.slice((this.page - 1) * this.resultsPerPage, this.page * this.resultsPerPage)
  }

  profileQuery(id:number) {
    const idType = this.pubType.toLowerCase() + 'Id'
    const result = {
      name: 'SerialProfile',
      query: {},
    }
    result.query[idType] = id
    return result
  }

  publicationQuery(id:number) {
    const idType = this.pubType.toLowerCase().substr(0, 1) + 'i'
    const result = {
      name: 'PublicationsSearch',
      query: {
        query: `auid:${this.authorId} ${idType}:${id}`,
      },
    }
    return result
  }
}
