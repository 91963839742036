var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{attrs:{"title":"Publications (by number of citations)"}},[_c('BTable',{attrs:{"small":"","striped":"","fields":_vm.fields,"items":_vm.author.pubClassificationCounts.citationsByClassification,"per-page":_vm.perPage,"sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(description)",fn:function(row){return [_c('RouterLink',{attrs:{"to":{
            name:'SearchMsc',
            query:{
              text: row.item.code,
            },
          }}},[_vm._v(" "+_vm._s(row.item.code)+" - "+_vm._s(row.value)+" ")])]}},{key:"cell(total)",fn:function(row){return [_c('RouterLink',{attrs:{"to":{
            name:'PublicationsSearch',
            query: {
              query:`auid:${_vm.author.id} pc:${row.item.code}`,
              sort:'citations',
            }
          }}},[_vm._v(" "+_vm._s(row.item.total)+" ")])]}}])}),(_vm.author.pubClassificationCounts.citationsByClassification.length > 5 && _vm.perPage <= _vm.author.pubClassificationCounts.citationsByClassification.length)?_c('BButton',{attrs:{"variant":"primary"},on:{"click":_vm.showMoreItems}},[_vm._v(" More ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }