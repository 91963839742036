
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Author } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class AuthorExtraInfoComponent extends Vue {
  @Prop({ required: true }) author!: Author
  @Prop({ default: false }) disableLinks!: boolean

  //
  // COMPUTED PROPERTIES
  //

  get extraInfoAvailable() {
    return this.emailAvailable || this.websiteAvailable || this.nameInNativeScriptAvailable
  }

  get emailAvailable() {
    return this.author.extraInfo && this.author.extraInfo.email &&
      this.author.extraInfo.email.trim().length > 0
  }

  get websiteAvailable() {
    return this.author.extraInfo && this.author.extraInfo.website &&
      this.author.extraInfo.website.trim().length > 0
  }

  get nameInNativeScriptAvailable() {
    return this.author.extraInfo && this.author.extraInfo.nameInNativeScript &&
      this.author.extraInfo.nameInNativeScript.trim().length > 0
  }

  get onlyHostname() {
    if (this.author.extraInfo && this.author.extraInfo.website) {
      const hostname = this.author.extraInfo.website.match(/^https?:\/\/([^/]+)/i)
      return hostname ? hostname[1] : this.author.extraInfo.website
    }

    return ''
  }

  get totalReviews() {
    return this.author.totalReviews ? this.author.totalReviews : 0
  }

  // METHODS
  onTotalReviewsClick() {
    this.$emit('total-reviews-clicked')
  }
}
