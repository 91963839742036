var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.classifications),function(msc,index){return [(!msc.invalid || (msc.invalid && _vm.showInvalidItems))?_c('div',{key:index,class:{
          'invalid': msc.invalid,
          'text-danger': msc.invalid,
          'extra-indent': msc.extraIndent,
          'default-cursor': !_vm.checkIfHasSublevel(msc, _vm.depth),
        },attrs:{"id":msc.code,"role":"listitem"}},[_c('div',{class:[{
                     'default-cursor': !_vm.checkIfHasSublevel(msc, _vm.depth),
                   },
                   'item',
          ],on:{"click":function($event){return _vm.open(msc, _vm.depth)}}},[(_vm.checkIfHasSublevel(msc, _vm.depth))?_c('button',{staticClass:"extra-indent",attrs:{"title":_vm.checkIfHasSublevel(msc, _vm.depth) ? 'Expand ' + msc.code + ' details' : 'Collapse ' + msc.code + ' details',"aria-label":_vm.checkIfHasSublevel(msc, _vm.depth) ? 'Expand ' + msc.code + ' details' : 'Collapse ' + msc.code + ' details'}},[_c('span',{staticClass:"icon",class:{'visible': _vm.checkIfHasSublevel(msc, _vm.depth)},attrs:{"role":"presentation"}},[_c('FontAwesomeIcon',{attrs:{"icon":msc.opened ? 'caret-down' : 'caret-right'}})],1)]):_vm._e(),_c('div',{staticClass:"msc-description",attrs:{"id":msc.code + '-description'}},[_c('span',{staticClass:"code",domProps:{"innerHTML":_vm._s(msc.codeHTML ? msc.codeHTML : msc.code)}}),_c('span',{staticClass:"range text-secondary",class:{'longer': msc.invalid}},[(!msc.invalid)?_c('span',{domProps:{"innerHTML":_vm._s(`(${msc.range})`)}}):_c('span',{domProps:{"innerHTML":_vm._s(`(Historical; ${ msc.range })`)}})]),_c('span',{staticClass:"desc",domProps:{"innerHTML":_vm._s(msc.description)}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(msc.opened || msc.loadingChildren),expression:"msc.opened || msc.loadingChildren"}],key:`${index}-children`,staticClass:"extra-indent",attrs:{"role":"list"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(msc.loadingChildren),expression:"msc.loadingChildren"}],key:`${index}-loading`,staticClass:"text-center mt-3 text-warning"},[_c('BSpinner')],1),_c('MSCSearchTree',{directives:[{name:"show",rawName:"v-show",value:(msc.opened && !msc.loadingChildren),expression:"msc.opened && !msc.loadingChildren"}],attrs:{"classifications":msc.children,"depth":_vm.depth + 1,"show-invalid-items":_vm.showInvalidItems},on:{"update:classification":_vm.open}})],1)]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }