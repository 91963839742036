
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Card } from '@/components/card'
import { SeriesResults } from '@/components/search-results/series-results'
import { SearchParams } from '@/global-types'

@Component({
  components: {
    Card,
    SeriesResults,
  },
})
export default class Series extends Vue {
  @Prop({ required: true }) seriesIds!: number[]

  searchParamsInternal: SearchParams = {
    query: '',
    pageNumber: 1,
    pageSize: 5,
    sortBy: '',
    facets: '',
  }

  get searchParams() {
    const seriesIds = this.seriesIds ? `si:(${this.seriesIds.join(' OR ')})` : ''

    return {
      ...this.searchParamsInternal,
      query: `${seriesIds}`.trim(),
    }
  }

  set searchParams(value: SearchParams) {
    this.searchParamsInternal = value
  }
}
