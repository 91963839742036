
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Author } from './types'
import { Card } from '@/components/card'
import { PublicationsResults } from '@/components/search-results/publications-results'
import { SearchParams } from '@/global-types'

@Component({
  components: {
    Card,
    PublicationsResults,
  },
})
export default class Publications extends Vue {
  @Prop({ required: true }) author!: Author
  @Prop({ default: false }) unsubbed!: boolean // if true, display the unsubscribed view of MathSciNet
  @Prop({ default: 0 }) tabIndex!: number

  internalTabIndex = 0
  pageSizeKey = 'authorProfilePageSize'
  defaultPageSize = 5
  pageSize = localStorage.getItem(this.pageSizeKey) !== null ? parseInt(localStorage.getItem(this.pageSizeKey)!) : this.defaultPageSize

  authoredSearchParams: SearchParams = {
    query: `auid:${this.author.id}`,
    pageNumber: 1,
    // pageSize: this.pageSize,
    pageSize: this.pageSize,
    sortBy: 'newest',
    facets: '',
  }

  relatedSearchParams: SearchParams = {
    query: `mauid:${this.author.id}`,
    pageNumber: 1,
    pageSize: this.pageSize,
    sortBy: 'newest',
    facets: '',
  }

  mostCitedSearchParams: SearchParams = {
    query: `auid:${this.author.id}`,
    pageNumber: 1,
    pageSize: this.pageSize,
    sortBy: 'citations',
    facets: '',
  }

  reviewsSearchParams: SearchParams = {
    query: `ri:${this.author.id}`,
    pageNumber: 1,
    pageSize: this.pageSize,
    sortBy: 'newest',
    facets: '',
  }

  updatePageSize() {
    this.pageSize = localStorage.getItem(this.pageSizeKey) !== null ? parseInt(localStorage.getItem(this.pageSizeKey)!) : this.defaultPageSize
    this.authoredSearchParams.pageSize = this.pageSize
    this.relatedSearchParams.pageSize = this.pageSize
    this.mostCitedSearchParams.pageSize = this.pageSize
    this.reviewsSearchParams.pageSize = this.pageSize
  }

  @Watch('tabIndex')
  onTabIndexChange() {
    this.internalTabIndex = this.tabIndex
  }
}
