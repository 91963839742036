
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Author, CoAuthor } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class CoAuthors extends Vue {
  @Prop({ required: true }) author!: Author
  @Prop({ default: false }) isPublic!: boolean

  //
  // REACTIVE PROPERTIES
  //
  pageNumber = 1
  perPage = 5
  sortingField = 'totalCollaborations'
  sortingDirection = 'desc'

  get coAuthors() {
    const coAuthorWithExtraInfo = this.author.coAuthors.map(coAuthor => {
      if (!this.author.coAuthorsExtraInfo) return coAuthor

      const extraInfo = this.author.coAuthorsExtraInfo.find(info => info.authorId === coAuthor.authorId)
      const totalCitations = extraInfo?.totalCitations
      const earliestPublicationYear = extraInfo?.earliestPublicationYear
      return { ...coAuthor, totalCitations, earliestPublicationYear }
    })

    return coAuthorWithExtraInfo
      .sort((a, b) => {
        const left = a[this.sortingField as keyof CoAuthor]
        const right = b[this.sortingField as keyof CoAuthor]

        if (left && right && left < right) {
          return this.sortingDirection === 'desc' ? 1 : -1
        }
        if (left && right && left > right) {
          return this.sortingDirection === 'desc' ? -1 : 1
        }
        return 0
      })
      .slice(0, this.perPage * this.pageNumber)
  }

  get headerClass() {
    const sortDirectionStyle = this.sortingDirection === 'desc' ? 'bg-sort-down' : 'bg-sort-up'
    const baseStyle = 'bg-sort-pos bg-sort'
    const style = `${baseStyle} ${sortDirectionStyle}`

    return {
      name: this.sortingField === 'name' ? style : baseStyle,
      totalCollaborations: this.sortingField === 'totalCollaborations' ? style : baseStyle,
      totalCitations: this.sortingField === 'totalCitations' ? style : baseStyle,
      earliestPublicationYear: this.sortingField === 'earliestPublicationYear' ? style : baseStyle,
    }
  }

  moreCoAuthors() {
    this.pageNumber++
  }

  allCoAuthors() {
    this.pageNumber = Math.ceil(this.author.coAuthors.length / this.perPage)
  }

  collapseCoAuthors() {
    this.pageNumber = 1
    const element = this.$refs.coauthors as HTMLElement
    element.scrollIntoView()
    // window.scrollTo({top:element.top})
  }

  lessCoAuthors() {
    this.pageNumber--
  }

  sort(field: string) {
    if (field !== this.sortingField) {
      this.sortDirection('up')
    } else {
      this.sortDirection()
    }

    this.sortBy(field)
  }

  sortBy(field: string) {
    this.sortingField = field
  }

  sortDirection(direction?: string) {
    if (direction) {
      this.sortingDirection = direction
    } else {
      this.sortingDirection = this.sortingDirection === 'desc' ? 'up' : 'desc'
    }
  }
}
