var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Card',{staticClass:"mt-3 mt-md-0",attrs:{"title":'Author Details',"show-on-small-screen-only":""}},[_c('BRow',[_c('BCol',{staticClass:"px-4",attrs:{"sm":"12","md":"6"}},[_c('table',{staticClass:"pubs-table"},[_c('tr',[_c('th',[_vm._v("MR Author ID")]),_c('td',[_vm._v(_vm._s(_vm.author.id))])]),_c('tr',[_c('th',[_vm._v("Earliest Indexed Publication")]),_c('td',[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(_vm.author.earliestPublicationYear)+" ")]):_c('RouterLink',{staticClass:"min-accessibility-spacing",attrs:{"to":{
                  name: 'PublicationsSearch',
                  query: {
                    query: `auid:${_vm.author.id} y:${_vm.author.earliestPublicationYear}`,
                  }
                }}},[_vm._v(" "+_vm._s(_vm.author.earliestPublicationYear)+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Total Publications")]),_c('td',[(_vm.disableLinks)?_c('div',[_vm._v(" "+_vm._s(_vm.author.totalPublications.toLocaleString())+" ")]):_c('RouterLink',{staticClass:"min-accessibility-spacing",attrs:{"to":{
                  name: 'PublicationsSearch',
                  query: {
                    query: `auid:${_vm.author.id}`,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.author.totalPublications.toLocaleString())+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Total Related Publications")]),_c('td',[(_vm.disableLinks || _vm.author.totalRelatedPublications === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.author.totalRelatedPublications.toLocaleString())+" ")]):_c('RouterLink',{staticClass:"min-accessibility-spacing",attrs:{"to":{
                  name: 'PublicationsSearch',
                  query: {
                    query: `mauid:${_vm.author.id}`,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.author.totalRelatedPublications.toLocaleString())+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Total Reviews")]),_c('td',[(_vm.disableLinks || _vm.totalReviews === 0)?_c('span',[_vm._v(" "+_vm._s(_vm.totalReviews)+" ")]):_c('BLink',{on:{"click":_vm.onTotalReviewsClick}},[_vm._v(" "+_vm._s(_vm.totalReviews.toLocaleString())+" ")])],1)]),_c('tr',[_c('th',[_vm._v("Total Citations")]),_c('td',[(_vm.disableLinks || _vm.author.totalPapersCitingAuthor === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.author.totalCitations.toLocaleString())+" in "+_vm._s(_vm.author.totalPapersCitingAuthor.toLocaleString())+" publications ")]):_c('RouterLink',{staticClass:"min-accessibility-spacing",attrs:{"to":{
                  name: 'PublicationsSearch',
                  query: {
                    query: `rla:${_vm.author.id}`,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.author.totalCitations.toLocaleString())+" in "+_vm._s(_vm.author.totalPapersCitingAuthor.toLocaleString())+" publications ")])],1)]),_c('tr',[_c('th',[_vm._v("Unique Citing Authors")]),_c('td',[_vm._v(" "+_vm._s(_vm.author.totalCitingAuthorCount.toLocaleString())+" ")])])])]),_c('BCol',{staticClass:"px-4",attrs:{"sm":"12","md":"6"}},[_c('table',{staticClass:"pubs-table"},[(_vm.emailAvailable)?_c('tr',[_c('td',[_vm._v("Email")]),_c('td',[_c('a',{attrs:{"href":`mailto:${_vm.author.extraInfo.email}`}},[_vm._v(_vm._s(_vm.author.extraInfo.email))])])]):_vm._e(),(_vm.websiteAvailable)?_c('tr',[_c('td',[_vm._v("Website")]),_c('td',[_c('a',{attrs:{"href":_vm.author.extraInfo.website,"title":_vm.author.extraInfo.website,"target":"_blank"}},[_vm._v(_vm._s(_vm.onlyHostname))])])]):_vm._e(),(_vm.nameInNativeScriptAvailable)?_c('tr',[_c('td',[_vm._v("Name in Native Script")]),_c('td',[_vm._v(_vm._s(_vm.author.extraInfo.nameInNativeScript))])]):_vm._e(),_c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":"2"}},[_c('RouterLink',{staticClass:"min-accessibility-spacing",attrs:{"to":{ name: 'FreeToolsCollabDist', query: { source: _vm.author.id } }}},[_vm._v(" Collaboration Distance ")])],1)]),_c('tr',[(_vm.author.mgpId)?_c('td',{staticClass:"text-left",attrs:{"colspan":"2"}},[_c('a',{attrs:{"href":`https://www.mathgenealogy.org/id.php?id=${_vm.author.mgpId}`,"target":"_blank"}},[_vm._v(" Mathematics Genealogy Project ")])]):_vm._e()]),_c('tr',[(_vm.author.macTutor && _vm.author.macTutor.pageUrl)?_c('td',{staticClass:"text-left",attrs:{"colspan":"2"}},[_c('a',{attrs:{"href":_vm.author.macTutor.pageUrl,"target":"_blank"}},[_vm._v(" MacTutor History of Mathematics Archive ")])]):_vm._e()])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }