export type SearchTab = {
  readonly text: string
  readonly shortText: string
  readonly route: { name: string }
}

const searchMSC: SearchTab = {
  text: 'Search MSC',
  shortText: 'MSC',
  route: { name: 'FreeToolsSearchMsc' },
}

const collabDistance: SearchTab = {
  text: 'Collaboration Distance',
  shortText: 'Collabs',
  route: { name: 'FreeToolsCollabDist' },
}

const currentJournals: SearchTab = {
  text: 'Recent Issues',
  shortText: 'Recent Iss.',
  route: { name: 'FreeToolsCurJournals' },
}

const curretPublications: SearchTab = {
  text: 'Current Publications',
  shortText: 'Cur. Pub.',
  route: { name: 'FreeToolsCurPublications' },
}

const currentIndexOfStatistics: SearchTab = {
  text: 'Current Index of Statistics',
  shortText: 'CIS',
  route: { name: 'CIS' },
}

const mrLookup: SearchTab = {
  text: 'MR Lookup',
  shortText: 'MR Lookup',
  route: { name: 'MRLookup' },
}

const mRef: SearchTab = {
  text: 'MRef',
  shortText: 'MRef',
  route: { name: 'MRef' },
}

export const SearchTabs = {
  searchMSC,
  collabDistance,
  currentJournals,
  curretPublications,
  currentIndexOfStatistics,
  mrLookup,
  mRef,
}

export type FreeToolsMSCResult = {
  code: string
  codeHTML: string
  description: string
  level: number
  startYear: string
  endYear: string
  group: string
}

export type Classification = {
  code: string
  codeHTML: string
  range: string
  description: string
  invalid: boolean
  opened: boolean
  children: Classification[]
  group: string
  extraIndent: boolean
  loadingChildren: boolean
}

export type AuthorResult = {
  id: number
  name: string
}

export type CollabDistancePath = {
  path: number[]
}

export type AuthorId = {
  authId: number
}

export type CollabDistancePaper = {
  paperId: number
  authors: AuthorId[]
}

export type JournalsResult = {
  journalId: number
  journalTitle: string
  journalAbbrev: string
  journalCode: string
  issueId: number
  recdNum: number
  year: string
  volume: string
  issueNumber: string
  suppl: string
}

export type MRefResponse = {
  paperId?: number
}

export type CollabDistance = {
  sourceAuthor?: AuthorResult,
  targetAuthor?: AuthorResult,
  paperId?: number,
}
