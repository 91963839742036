import { Author } from './types'

export function getLastNameInitial(author: Author | null): string {
  const lastName = (author ? author.profileName.split(',') : [''])[0].trim()
  if (lastName.length === 0) {
    if (author) {
      return author.profileName[0].toUpperCase()
    }
    return ''
  }
  // Take first capital letter, if it exists. Otherwise, take first letter
  for (let i = 0; i < lastName.length; i++) {
    if (lastName[i].toUpperCase() === lastName[i]) {
      return lastName[i]
    }
  }
  return lastName.length > 0 ? lastName[0].toUpperCase() : ''
}
