
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { Author, AuthorExtraInfo } from './types'
import { getLastNameInitial } from './utils'
import { AMSAccountAPI, AuthorsAPI } from '@/api'

@Component
export default class AuthorEdit extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Ref('croppa') croppa!: any

  @Prop({ required: true }) author!: Author

  //
  // REACTIVE PROPERTIES
  //
  internalShowModal = false
  name = ''
  email = ''
  website = ''
  picture: string | null = null
  showImageErrorMessage = false
  imageErrorMessage = ''
  showFormErrorMessage = false
  formErrorMessage = 'Error updating author profile. Try again or contact support.'
  isLoggedIn = false
  croppaLoading = false
  imageChanged = false
  saving = false

  //
  // WATCHERS
  //
  @Watch('showModal', { immediate: true })
  onShowModalChange(showModal: boolean) {
    if (showModal) {
      this.setValues(this.author)
    }
  }

  @Watch('$route', { immediate: true })
  onRouteChange() {
    this.checkIfAMSLoggedIn()
  }

  //
  // COMPUTED PROPERTIES
  //
  get showModal() {
    return this.internalShowModal && this.isLoggedIn
  }

  set showModal(showModal: boolean) {
    this.internalShowModal = showModal
  }

  get getLastNameInitial() {
    return getLastNameInitial(this.author)
  }

  get prefixedPicture() {
    if (this.picture) {
      return `data:image/png;base64,${this.picture}`
    }

    return null
  }

  get formModified() {
    const nameInNativeScript = this.author.extraInfo ? this.author.extraInfo.nameInNativeScript : ''
    const email = this.author.extraInfo ? this.author.extraInfo.email : ''
    const website = this.author.extraInfo ? this.author.extraInfo.website : ''

    return (
      this.name !== nameInNativeScript ||
      this.email !== email ||
      this.website !== website
    )
  }

  get pictureModified() {
    return this.imageChanged
  }

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    //
  }

  destroyed() {
    //
  }

  //
  // EVENT HANDLERS
  //
  async onClickEditAuthorProfile() {
    await this.checkIfAMSLoggedIn()

    if (this.isLoggedIn) {
      this.showModal = true
    } else {
      this.onClickLoginToEdit()
    }
  }

  onClickLoginToEdit() {
    const sep = window.location.href.indexOf('?') === -1 ? '?' : '&'
    const url = window.location.href.indexOf('edit=true') === -1
      ? `${window.location.href}${sep}edit=true`
      : window.location.href

    const contextPath = this.$store.state.AppConfigModule.appConfig.contextPath

    AMSAccountAPI.redirectToAMSLogin(url, contextPath)
  }

  onClickCancel() {
    this.showModal = false
    this.$emit('cancel')
  }

  onClickSave() {
    this.$validator.validateAll().then(async(result) => {
      if (!result) {
        return
      }

      try {
        this.saving = true
        await AuthorsAPI.updateExtraInfo(
          this.author.id,
          this.name,
          this.email,
          this.website
        )

        const file = await this.croppa.promisedBlob('image/jpeg')

        if (file) {
          await AuthorsAPI.uploadPicture(this.author.id, file)
        } else {
          await AuthorsAPI.deletePicture(this.author.id)
        }

        const extraInfo = await AuthorsAPI.getExtraInfo(this.author.id)

        this.$emit('saved', {
          email: extraInfo.email,
          website: extraInfo.website,
          nameInNativeScript: extraInfo.nameInNativeScript,
          picture: extraInfo.picture,
        } as AuthorExtraInfo)

        this.showFormErrorMessage = false
        this.showModal = false
      } catch (err: any) {
        if (AMSAccountAPI.isUnauthorized(err)) {
          this.onClickLoginToEdit()
        } else {
          if (err.response && err.response.data) {
            this.formErrorMessage = err.response.data
          } else {
            this.formErrorMessage = 'Error updating author profile. Try again or contact spport.'
          }
          this.showFormErrorMessage = true
        }
      } finally {
        this.saving = false
      }
    })
  }

  onClickChangePicture() {
    this.croppa.chooseFile()
  }

  onClickRemovePicture() {
    this.croppa.remove()
    this.imageChanged = true
  }

  onCroppaLoadingStart() {
    this.croppaLoading = true
  }

  onCroppaLoadingEnd() {
    this.croppaLoading = false
  }

  onCroppaFileTypeMismatch() {
    this.imageErrorMessage = 'Only image files are supported'
    this.showImageErrorMessage = true
  }

  onCroppaFileSizeExceed() {
    this.imageErrorMessage = 'File size must be less than 20MB'
    this.showImageErrorMessage = true
  }

  onCroppaFileChoose() {
    this.imageErrorMessage = ''
    this.showImageErrorMessage = false
    this.imageChanged = true
  }

  //
  // METHODS
  //

  validateState(ref: string, value: string) {
    if (
      value.trim().length > 0 &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.veeFields[ref] &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return !this.veeErrors.has(ref)
    }

    return null
  }

  setValues(author: Author) {
    this.name = author.extraInfo ? author.extraInfo.nameInNativeScript : ''
    this.email = author.extraInfo ? author.extraInfo.email : ''
    this.website = author.extraInfo ? author.extraInfo.website : ''
    this.picture = author.extraInfo ? author.extraInfo.picture : null
    this.showImageErrorMessage = false
    this.showFormErrorMessage = false
  }

  async checkIfAMSLoggedIn() {
    this.isLoggedIn = await AMSAccountAPI.isLoggedIn()

    if (!this.isLoggedIn && this.$route.query.edit === 'true') {
      this.onClickLoginToEdit()
    } else if (this.$route.query.edit === 'true') {
      this.showModal = true
    }
  }
}
