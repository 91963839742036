
import { Vue, Component, Prop } from 'vue-property-decorator'
import { CollabDistance } from '@/components/freetools/types'

@Component
export default class CollabDistanceTable extends Vue {
  @Prop({ required: true }) collabDistance!: CollabDistance[]

  //
  // COMPUTED PROPERTIES
  //
  get longestId() {
    const lengths = this.collabDistance.map(item => item.paperId ? item.paperId.toString().length : 0)

    return Math.max(...lengths)
  }

  //
  // METHODS
  //
  formatPaperId(paperId?: number) {
    return paperId ? '0'.repeat(this.longestId - paperId.toString().length) + paperId : '-'
  }
}
