import parser from './parser_generated'

export interface Location {
  start: number
  end: number
}

export interface Data {
  type: string
  value: string
  operator?: string
  location: Location
}

export interface FieldGroup {
  type: string
  value: Data[]
  location: Location
}

export interface Operator {
  value: string
  location: Location
}

export interface Field {
  type: string
  name: {
    value: string
    location: Location
  }
  value: Data | FieldGroup
  operator?: Operator
  location: Location
}

export interface ParserResult {
  text: Data
  fields: Field[]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parse = (text: string, options: any = {}): ParserResult | null => {
  try {
    return parser.parse(text, options) as ParserResult
  } catch (e) {
    // TODO: Build proccess should remove this line, this should be only for development
    console.error(e)
  }

  return null
}

export default { ...parser, parse }
