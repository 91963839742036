
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Card } from '@/components/card'
import { JournalResults } from '@/components/search-results/journal-results'
import { SearchParams } from '@/global-types'

@Component({
  components: {
    Card,
    JournalResults,
  },
})
export default class Journal extends Vue {
  @Prop({ required: true }) journalIds!: number[]

  searchParamsInternal: SearchParams = {
    query: '',
    pageNumber: 1,
    pageSize: 5,
    sortBy: '',
    facets: '',
  }

  get searchParams() {
    const journalIds = this.journalIds ? `ji:(${this.journalIds.join(' OR ')})` : ''

    return {
      ...this.searchParamsInternal,
      query: `${journalIds}`.trim(),
    }
  }

  set searchParams(value: SearchParams) {
    this.searchParamsInternal = value
  }
}
