
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Author } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class AuthorTotalPubs extends Vue {
  @Prop({ required: true }) author!: Author
  @Prop({ default: false }) disableLinks!: boolean
}
