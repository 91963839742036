
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Author } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class PublishedAs extends Vue {
  @Prop({ required: true }) author!: Author
  @Prop({ default: false }) disableLinks!: boolean

  //
  // REACTIVE PROPERTIES
  //
  opened = false

  get variationsInfo() {
    return this.author.publishedAs.map(item => {
      const variationExtraInfo = this.author.variationsExtraInfo.find(v => v.name.trim() === item.name.trim())
      let citations = 0
      let earliestPublication = { year: 0, paperId: 0 }

      if (variationExtraInfo) {
        citations = variationExtraInfo.citations
        earliestPublication = variationExtraInfo.earliestPublication
      }

      return { ...item, citations, earliestPublication }
    })
  }
}
