
import { Vue, Component, Prop } from 'vue-property-decorator'
import { SearchTab, SearchTabs } from '@/components/freetools'

@Component
export default class NavigationTabs extends Vue {
  //
  // PROPS
  //
  @Prop({ required: true }) selectedTab!: SearchTab

  //
  // REACTIVE PROPERTIES
  //
  tabs: SearchTab[] = [
    SearchTabs.searchMSC,
    SearchTabs.collabDistance,
    // SearchTabs.currentJournals,
    SearchTabs.curretPublications,
    SearchTabs.currentIndexOfStatistics,
    SearchTabs.mrLookup,
    SearchTabs.mRef,
  ]

  //
  // LIFE CYCLE HOOKS
  //

  //
  // METHODS
  //
}
