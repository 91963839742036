
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { Card } from '@/components/card'
import { CollabDistanceTable, CollabDistance, getCollaborationDistance, AuthorResult } from '@/components/freetools'
import { FreeToolsAPI } from '@/api/freetools-api'
import { isNumber } from '@/utils/utils'

@Component({
  components: {
    Card,
    CollabDistanceTable,
  },
})
export default class CollabDistanceCard extends Vue {
  @Ref() readonly targetNameSearchButton!: HTMLElement
  @Ref() readonly targetNameClearButton!: HTMLElement
  @Ref() readonly targetUseErdosButton!: HTMLElement
  @Ref() readonly targetCol!: HTMLElement

  @Prop() authorId!: number

  //
  // REACTIVE PROPERTIES
  //
  collabDistance: CollabDistance[] = []
  loading = false
  erdosInfo: AuthorResult | null = null
  targetAuthorInfo: AuthorResult | null = null
  targetNameInputText = ''
  targetNames: AuthorResult[] = []
  loadingTargetAuthorList = false
  targetDropdownWidth = 0
  noResultsTargetAuthor = false
  noCollabDistance = false

  //
  // HOOKS
  //
  async mounted() {
    const authors = await FreeToolsAPI.getAuthorsByIds([189017], 1)

    if (authors && authors.length > 0) {
      this.erdosInfo = authors[0]
    }
  }

  updated() {
    this.setTargetDropdownWidth()
  }

  //
  // METHODS
  //
  async searchAuthorCollabDist(targetText?: string) {
    if (!targetText) return

    this.loadingTargetAuthorList = true
    this.noResultsTargetAuthor = false
    this.noCollabDistance = false
    const authorInfo = await this.getAuthorInfo(targetText)
    this.loadingTargetAuthorList = false

    if (authorInfo.length === 0) {
      this.noResultsTargetAuthor = true
      return
    }

    if (authorInfo.length === 1) {
      this.searchCollabDistTo(authorInfo[0])
      return
    }

    this.targetNames = authorInfo
  }

  async searchErdosCollabDist() {
    if (!this.erdosInfo) return

    this.searchCollabDistTo(this.erdosInfo)
  }

  async getAuthorInfo(targetText: string) {
    if (isNumber(targetText.trim())) {
      const authors = await FreeToolsAPI.getAuthorsByIds([parseInt(targetText)], 1)

      if (authors && authors.length > 0) {
        return authors
      }

      return []
    } else {
      return (await FreeToolsAPI.getAuthorsByName(targetText.trim(), 50))
        .map(author => ({
          id: author.id,
          name: author.name,
        }))
    }
  }

  async searchCollabDistTo(author?: AuthorResult) {
    if (!author) return

    try {
      this.loading = true
      this.collabDistance = []
      this.noCollabDistance = false
      this.targetAuthorInfo = author
      const collabDistance = await getCollaborationDistance(
        this.authorId,
        this.targetAuthorInfo.id,
      )

      if (collabDistance) {
        this.collabDistance = collabDistance
      } else {
        this.noCollabDistance = true
        this.collabDistance = []
      }
    } catch {
      this.noCollabDistance = true
    } finally {
      this.targetNameInputText = ''
      this.targetNames = []
      this.loading = false
    }
  }

  setTargetDropdownWidth() {
    if (this.targetNameSearchButton || this.targetNameClearButton) {
      const targetColStyle = window.getComputedStyle(this.targetCol)
      const targetNameSearchButtonStyle = window.getComputedStyle(this.targetNameSearchButton || this.targetNameClearButton)
      const widthNoPaddingTargetCol = parseInt(targetColStyle.width) - parseInt(targetColStyle.paddingLeft) - parseInt(targetColStyle.paddingRight)
      const searchButtonWidth = parseInt(targetNameSearchButtonStyle.width)

      this.targetDropdownWidth = widthNoPaddingTargetCol - searchButtonWidth
    }
  }

  clearTargetNameInput() {
    this.targetNameInputText = ''
    this.targetNames = []
    this.noResultsTargetAuthor = false
  }
}
