
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Classification } from '@/components/freetools'
import { updateMathJax } from '@/utils/utils'

@Component({
  name: 'MSCSearchTree',
})
export default class MSCSearchTree extends Vue {
  @Prop() classifications!: Classification[]
  @Prop({ default: 1 }) depth!: number
  @Prop({ default: false }) showInvalidItems!: boolean

  //
  // LIFE CYCLE HOOKS
  //
  mounted() {
    updateMathJax()
  }

  updated() {
    updateMathJax()
  }

  //
  // METHODS
  //
  async open(msc: Classification, depth: number) {
    this.$emit('update:classification', msc, depth)
  }

  checkIfHasSublevel(msc: Classification, depth: number) {
    // return (!msc.invalid && depth < 2) || msc.children.length > 0
    return (depth < 2) || msc.children.length > 0
  }
}
