import { CollabDistance } from '@/components/freetools/types'
import { FreeToolsAPI } from '@/api/freetools-api'

export async function getCollaborationDistance(sourceAuthorId?: number, targetAuthorId?: number): Promise<CollabDistance[] | undefined> {
  const pathResult = await FreeToolsAPI.getCollabDistancePath(
    sourceAuthorId,
    targetAuthorId,
  )

  if (pathResult) {
    const authors = await FreeToolsAPI.getAuthorsByIds(pathResult.path, pathResult.path.length)
    const collaborations: [number, number][] = []
    const collabDistance: CollabDistance[] = []

    for (let i = 0; i < pathResult.path.length - 1; i++) {
      const sourceAuthor = authors.find(author => author.id === pathResult.path[i])
      const targetAuthor = authors.find(author => author.id === pathResult.path[i + 1])

      if (sourceAuthor && targetAuthor) {
        collaborations.push([sourceAuthor.id, targetAuthor.id])
      }

      collabDistance.push({
        sourceAuthor,
        targetAuthor,
      })
    }

    const papers = await FreeToolsAPI.getCollabDistancePapers(collaborations)

    if (papers.length > 0) {
      const updated = collabDistance.map(collab => {
        const paper = papers.find(paper =>
          paper.authors.find(author => author.authId === collab.sourceAuthor?.id) &&
          paper.authors.find(author => author.authId === collab.targetAuthor?.id)
        )

        if (paper) {
          collab.paperId = paper.paperId
        }

        return collab
      })

      return updated
    }
  }

  return undefined
}
