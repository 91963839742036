
import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'

@Component
export default class SearchPagination extends Vue {
  @Prop({ type: Boolean, default: false }) loading!: boolean
  @Prop({ required: true }) totalItems!: number
  // @Prop({ type: Boolean, required: false, default: false }) unsubbed!: boolean

  @PropSync('currentPage', { type: Number }) syncedCurrentPage!: number
  @PropSync('pageSize', { type: Number }) syncedPageSize!: number

  pageSizes = [5, 10, 20, 50, 100]
  // localPageSize = 20 // using the syncedPageSize doesn't keep the top and bottom page size selectors in sync... ironically

  //
  // WATCHERS
  //
  @Watch('syncedPageSize', { immediate: true })
  syncedPageSizeChanged(newLocalPageSize) {
    if (newLocalPageSize !== this.syncedPageSize) {
      localStorage.setItem(this.$route.name + 'PageSize', newLocalPageSize)
    }
  }

  // @Watch('localPageSize', { immediate: true })
  // updateSyncedPageSize(newLocalPageSize) {
  //   if (newLocalPageSize !== this.syncedPageSize) {
  //     localStorage.setItem(this.$route.name + 'PageSize', newLocalPageSize)
  //     this.syncedPageSize = parseInt(newLocalPageSize)
  //     // Can't updateCurrentPage(1) here, it must happen in the JournalResults, SeriesResults, AuthorResults, PublicationResults, etc.
  //   }
  // }

  // @Watch('syncedPageSize', { immediate: true })
  // updateLocalPageSize(newSyncPageSize) {
  //   if (newSyncPageSize !== this.localPageSize) {
  //     // Don't update the localStorage here. The user didn't select this new page size, it came from something like a URL (or being updated by the localPageSize)
  //     this.localPageSize = parseInt(newSyncPageSize)
  //   }
  // }

  //
  // COMPUTED PROPERTIES
  //
  get totalPaginationRows() {
    // "rows" = how many items that pagination should be paging over.
    return this.totalItems > 1000 ? 1000 : this.totalItems
  }

  //
  // METHODS
  //
  updateSyncedPageSize(pageSize: string) {
    this.syncedPageSize = parseInt(pageSize)
  }

  updateCurrentPage(pageNumber: string) {
    this.syncedCurrentPage = parseInt(pageNumber)
  }

  // get itemsLoaded() {
  //   return this.totalItems > 0
  // }

  // mounted() {
  //   if (this.syncedPageSize) {
  //     this.localPageSize = this.syncedPageSize
  //   }
  // }

  // getMoreResults() {
  //   this.$emit('more')
  // }
}
