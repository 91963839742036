import { getResult } from './utils'
import { FreeToolsMSCResult, AuthorResult, CollabDistancePath, CollabDistancePaper, JournalsResult, MRefResponse } from '@/components/freetools'
import { PromiseResults } from '@/global-types'
import { Publication } from '@/components/search-results/publications-results'
import { Article } from '@/components/article-page'

export const FreeToolsAPI = {

  async getFirstLevelMSC(): Promise<FreeToolsMSCResult[]> {
    return getResult('/api/freetools/msc/first-level')
  },

  async getGroupSublevelMSC(group: string): Promise<FreeToolsMSCResult[]> {
    return getResult(`/api/freetools/msc/group-sublevel/${group}`)
  },

  async searchClassifications(text: string): Promise<FreeToolsMSCResult[]> {
    return getResult(`/api/freetools/msc/search?text=${text}`)
  },

  async getAuthorsByName(name: string, total: number): Promise<AuthorResult[]> {
    return getResult(`/api/freetools/authors/search?name=${name}&total=${total}`)
  },

  async getAuthorsByIds(ids: number[], total: number): Promise<AuthorResult[]> {
    return getResult(`/api/freetools/authors/search?ids=${ids.join(',')}&total=${total}`)
  },

  async getCollabDistancePath(sourceAuthorId?: number, targetAuthorId?: number): Promise<CollabDistancePath> {
    return getResult(`/api/freetools/collab-distance/authors?sourceAuthorId=${sourceAuthorId}&targetAuthorId=${targetAuthorId}`)
  },

  async getCollabDistancePapers(collaborations: [number, number][]): Promise<CollabDistancePaper[]> {
    const collabs = collaborations
      .map(collab => `collaboration=${collab[0]},${collab[1]}`).join('&')

    return getResult(`/api/freetools/collab-distance/papers?${collabs}`)
  },

  async getJournalsByWeekBack(weekBack: string, currentPage: number, pageSize: number): PromiseResults<JournalsResult> {
    return getResult(`api/freetools/journals-by-week/${weekBack}`, { currentPage, pageSize })
  },

  async getCurrentPublications(query: string, currentPage: number, pageSize: number, sort?: string, order?: string): PromiseResults<Publication> {
    return getResult('/api/freetools/current-publications', { query, currentPage, pageSize, sort, order })
  },

  async mrLookup(
    author: string,
    title: string,
    journal: string,
    firstPage: string,
    lastPage: string,
    year: string,
  ): Promise<{all: {results: Article[], total: number}, dml: {results: Article[], total: number}}> {
    return getResult('/api/freetools/mrlookup', { author, title, journal, firstPage, lastPage, year })
  },

  async getMRef(ref: string): Promise<MRefResponse> {
    return getResult('/api/freetools/mref', { ref })
  },
}
