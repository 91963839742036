
import { Vue, Component, Prop } from 'vue-property-decorator'
import { Author } from './types'
import { Card } from '@/components/card'

@Component({
  components: {
    Card,
  },
})
export default class Classifications extends Vue {
  @Prop({ required: true }) author!: Author
  @Prop({ default: false }) isPublic!: boolean

  //
  // REACTIVE PROPERTIES
  //
  page = 1
  perPage = 5
  itemsToShow = this.page * this.perPage
  fields = [
    {
      key: 'description',
      label: 'Classification',
      sortable: true,
      thClass: 'user-select-none',
      formatter: (value: string) => {
        if (value.toLowerCase() === 'miscellaneous') {
          return 'Other'
        }

        return value.replace(/\[.*?\]|\{.*?\}/g, '')
      },
      tdAttr: (value: string) => {
        return {
          title: value,
        }
      },
    },
    {
      key: 'publications',
      label: 'Publications',
      sortable: true,
      thClass: 'user-select-none',
      class: 'text-center',
    },
    {
      key: 'citations',
      label: 'Citations',
      sortable: true,
      thClass: 'user-select-none',
      class: 'text-center',
    },
  ]

  showMoreItems() {
    this.page += 1
    this.itemsToShow = this.page * this.perPage
  }

  showAllItems() {
    this.page = Math.ceil(this.author.pubClassificationCounts.publicationsByPrimaryClassification.length / this.perPage)
    this.itemsToShow = this.author.pubClassificationCounts.publicationsByPrimaryClassification.length
  }

  collapseItems() {
    this.page = 1
    this.itemsToShow = this.perPage
  }

  showLessItems() {
    this.page -= 1
    this.itemsToShow = this.page * this.perPage
  }

  get combinedClassificataions() {
    // this.author.pubClassificationCounts
    // Join .citationsByClassification and .publicationsByPrimaryClassification by code
    const combined: {
      code: string
      description: string
      publications: number
      citations: number
    }[] = []
    for (let i = 0; i < this.author.pubClassificationCounts.publicationsByPrimaryClassification.length; i++) {
      const current = this.author.pubClassificationCounts.publicationsByPrimaryClassification[i]
      const cites = this.author.pubClassificationCounts.citationsByClassification.filter(x => x.code === current.code)[0]
      combined.push(
        {
          code: current.code,
          description: current.description,
          publications: current.total,
          citations: cites.total,
        }
      )
    }
    return combined
  }
}
